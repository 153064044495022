import Cookies from 'js-cookie';

export const getAccessToken = async (forceRefresh = false): Promise<string> => {
  const TOKEN_KEY = 'access_token';
  const AUTH_API_URL = 'https://legitgrails-api.com/1/authorize';
  const CLIENT_ID = 'af2ac997-ab74-4ee9-a90b-754d8c15dedf';
  const CLIENT_SECRET = '11485fdd-501e-4d02-8ded-7d1fbe63d85e';

  if (!forceRefresh) {
    const storedToken = Cookies.get(TOKEN_KEY);
    if (storedToken) {
      return storedToken;
    }
  }

  try {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'client-id': CLIENT_ID,
        'client-secret': CLIENT_SECRET,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to authenticate');
    }

    const data = await response.json();
    const token = data.access_token;

    // Store token in cookies
    Cookies.set(TOKEN_KEY, token, { secure: true, sameSite: 'strict' });

    return token;
  } catch (error) {
    console.error('Error fetching access token:', error);
    throw error;
  }
};

interface FetchWithAuthOptions extends RequestInit {
  skipRetry?: boolean; // In case you want to skip token refresh logic in some requests
}

/**
 * A wrapper around 'fetch' that automatically handles
 * 401 Unauthorized responses and retries with a fresh token.
 */
export async function fetchWithAuth<T>(url: string, options: FetchWithAuthOptions = {}): Promise<T> {
  const { skipRetry, ...fetchOptions } = options;

  // 1. Get existing token (or refresh if needed)
  let token = await getAccessToken();

  // 2. Make the request
  let response = await fetch(url, {
    ...fetchOptions,
    headers: {
      'Content-Type': 'application/json',
      ...(fetchOptions.headers ?? {}),
      Authorization: `Bearer ${token}`,
    },
  });

  // 3. If 401 and not skipping, refresh token and retry
  if (response.status === 401 && !skipRetry) {
    token = await getAccessToken(true); // forceRefresh = true
    response = await fetch(url, {
      ...fetchOptions,
      headers: {
        'Content-Type': 'application/json',
        ...(fetchOptions.headers ?? {}),
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // 4. Check for other possible errors
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Fetch error (${response.status}): ${response.statusText}\n${errorText}`);
  }

  // 5. Return response in JSON (assuming you expect JSON)
  return response.json() as Promise<T>;
}

export const fetchGetPhotoList = async <T>(testId: string, admin?: string): Promise<T> => {
  const url = `https://ymo8ktxz5m.execute-api.eu-west-2.amazonaws.com/staging/photos/resubmit?id=${testId}&client_id=5349be48-2c11-4bba-8a11-96ceb025f9c6${
    admin ? `&admin=${admin}` : ''
  }`;

  // Use the fetchWithAuth wrapper
  const body = await fetchWithAuth<T>(url, {
    method: 'GET',
  });
  return body;
};

export const postPhotos = async <T>(
  bodyRequest: { id: string; photos: Array<{ index: string; url: string }> },
  admin?: string,
): Promise<T> => {
  const newBody = admin ? { ...bodyRequest, admin } : bodyRequest;

  const body = await fetchWithAuth<T>('https://9qmmmgbfl0.execute-api.eu-west-2.amazonaws.com/1/photo/reupload', {
    method: 'POST',
    body: JSON.stringify({
      client_id: '5349be48-2c11-4bba-8a11-96ceb025f9c6',
      ...newBody,
    }),
  });

  return body;
};

export const rejectOrder = async <T>(uniqueCode: string): Promise<T> => {
  const url = `https://9qmmmgbfl0.execute-api.eu-west-2.amazonaws.com//staging/order?client-id=5349be48-2c11-4bba-8a11-96ceb025f9c6`;

  const body = await fetchWithAuth<T>(url, {
    method: 'DELETE',
    body: JSON.stringify({
      id: uniqueCode,
      outcome: 'canceled',
      user: 'photo_reuploader',
      outcome_reason: 'FPR',
    }),
  });

  return body;
};
